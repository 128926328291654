import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Review from "../components/review";
import CtaBanner from "../components/cta-banner";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Navigation from "../components/navigation";
import CookieBanner from "../components/cookie";
import Footer from "../components/footer";
import Montage from "../components/services/montage";
import ServicesList from "../components/services/services-list";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusiness";
import { Helmet } from "react-helmet";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
				publicURL
			}
			lampImg: file(relativePath: { eq: "home/lamp.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			sandwichImg: file(relativePath: { eq: "home/sandwich.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						transformOptions: { fit: INSIDE }
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			headshotImg: file(relativePath: { eq: "home/headshot.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			chefsImg: file(relativePath: { eq: "home/chefs.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			cafeImg: file(relativePath: { eq: "home/cafe.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			businessBrandingImg: file(
				relativePath: { eq: "services/business-branding.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			proHeadshotsImg: file(relativePath: { eq: "services/headshots.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						transformOptions: { fit: CONTAIN }
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			productImg: file(relativePath: { eq: "services/product.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			foodImg: file(relativePath: { eq: "services/food.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			creativeImg: file(relativePath: { eq: "services/creative.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			trainingImg: file(relativePath: { eq: "services/training.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImg = data.heroImg.publicURL;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
		],
	};

	return (
		<>
			<section className="min-vh-100">
				<LocalBusinessSchema />
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				<CookieBanner />
				<GatsbySeo
					title="Commercial Photographer | Corporate Photographer | Sussex"
					description="Im a highly experienced professional commercial photographer specialising in food photography, product photography and advertising photography. Based in Sussex."
					canonical="https://www.sarah-bennett.com"
					language="en"
					openGraph={{
						type: "website",
						url: "https://www.sarah-bennett.com",
						title:
							"Commercial Photographer | Corporate and Business Photographer | Sarah Bennett Photography",
						description:
							"Im a highly experienced commercial photographer specialising in food photography, product photography and business branding. Based in Eastbourne. Find out more.",
						images: [
							{
								url: `${siteUrl}${schemaImg}`,
								width: 1920,
								height: 1080,
								alt: "Commercial Photographer in East and West Sussex",
							},
						],
					}}
				/>

				<StaticImage
					src="../images/home-hero-2.jpg"
					alt="Home Image"
					className="vh-100 position-fixed"
					style={{ top: "0px", zIndex: "-9999" }}
				/>
				<Navigation />
			</section>
			<section className="pb-10 py-lg-10 bg-white" id="home-hero">
				<Container>
					<Row className="align-items-lg-center">
						<Col xs={12} lg={6} className="my-5 mt-md-6 my-lg-0">
							<h1 className="text-uppercase text-center text-lg-start text-muted">
								Commercial
								<br />
								Photographer <br />
								In Sussex
							</h1>
						</Col>
						<Col xs={12} lg={6}>
							<p>
								Does your brand new a reboot? Do your company photos look tired?
								Perhaps your headshots are looking a bit dated? Or maybe you
								have some new products you want to promote…Whatever your
								company’s commercial photography requirements are, I have
								experience working within the commercial sector and can help you
								with a photography shoot and create high quality pictures that
								tell YOUR story and project the right image for YOUR company.
							</p>
							<Button
								variant="primary cta-btn"
								as={Link}
								to="/contact-us#quote-form"
							>
								Get a Quote
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<Montage
				img1={data.lampImg.childImageSharp.gatsbyImageData}
				img2={data.sandwichImg.childImageSharp.gatsbyImageData}
				img3={data.headshotImg.childImageSharp.gatsbyImageData}
				img4={data.chefsImg.childImageSharp.gatsbyImageData}
				img5={data.cafeImg.childImageSharp.gatsbyImageData}
			/>
			<Review
				quote="Sarah is a total pleasure to work with; professional, flexible and bursting with ideas."
				author="Aneela Rose"
				company="MD Rose Media Group"
			/>
			<ServicesList />
			<section className="pt-md-10 pb-4 pb-md-0 bg-white">
				<Container>
					<Row>
						<Col className="mb-md-6">
							<h1 className="text-start text-md-center">
								Professional Commercial Photography
							</h1>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
					<Row className="pt-4 pt-md-0">
						<Col>
							<p>
								Professional photography is a great way to help your business
								stand out from competitors in your niche. With quality still
								life portraits, photographs and video, you can bring your
								business to life. Ideal for marketing campaigns, creative
								internal videos, event photography and much more, I can capture
								the images you need to impress clients and customers. Available
								in London, Brighton and the south, simply give me a call to find
								out more about how my services can help you capture the perfect
								photo.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-md-8 pb-4 pt-4 pb-md-0 bg-white">
				<Container>
					<Row>
						<Col className="mb-md-6">
							<h1 className="text-start text-md-center">
								Our Photography Production Process
							</h1>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-lg-6 pt-md-4 pt-5 bg-white">
				<Container>
					<Row className="pb-2">
						<Col>
							<h3>Step 1: Understanding Your Business</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								Whether your company is located in London, Brighton or another
								location throughout Sussex, Kent and Surrey as a commercial
								photographer I make it a top priority to understand the needs of
								your business. Understanding your needs allows me to create
								ideas tailored to your service, whilst also allowing us to be
								creative in our approach. During this step, we will discuss not
								only your project, but also your corporate goals. By
								understanding your goals, I can create professional commercial
								portraits that are ideal for advertising in your industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" pt-md-6 pt-5 bg-white">
				<Container>
					<Row className="pb-2">
						<Col>
							<h3>Step 2: Proposal</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								After an initial call, I will contact you directly to offer my
								proposal. This proposal will contain a brief that will highlight
								my time of arrival, the location of the shoot and the topics of
								the photoshoot itself. Although I work as a commercial
								photographer in Sussex, I'm also able to attend locations
								throughout London and the south East of England. If you have any
								issues with the proposal, simply give me a call and I will be
								happy to help. If needed, I can bring my mobile studio along to
								you and also hire additional photographers should the project
								require them. Once happy with the brief, we shall proceed to the
								shoot itself.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" pt-md-6 pt-5 bg-white">
				<Container>
					<Row className="pb-2">
						<Col>
							<h3>Step 3: Photography Shoot</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								Now we get to the part I love as a commercial photographer-
								bringing your commercial photography to life! I will arrive
								promptly at the studio or location we agreed upon, bringing all
								the equipment required to capture the perfect corporate image.
								The commercial photography session itself can take anywhere from
								a few hours to a few days but will be agreed upon in our brief.
								If you realise something has been forgotten before the
								photoshoot day, you can get in touch with me.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" pt-md-6 pt-5 pb-4 bg-white">
				<Container>
					<Row className="pb-2">
						<Col>
							<h3>Step 4: Processing And Delivery</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								It's no secret that editing is an important part of commercial
								photography. As your commercial photographer in Sussex, Kent or
								Surrey, I make it my priority to process and deliver the very
								best images for your company. Whether you've asked for{` `}
								<Link to="/services/personal-branding">
									Headshot photography
								</Link>
								,{" "}
								<Link to="/services/product-photography">
									Product photography
								</Link>
								, or{" "}
								<Link to="/services/business-branding">
									Branding photography
								</Link>
								, I will ensure the highest quality results. Once finished,
								photos will be available as digital transfers.
							</p>
							<p>
								If you're looking for a commercial photographer in Sussex,
								London or the south, give me a call today and discover how I can
								bring your visual ideas to life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pt-md-6 py-lg-10 pt-5 bg-white">
				<Container>
					<Row className="mb-3 mb-lg-5">
						<Col>
							<h3 className="text-start text-md-center">
								Meet Sarah Bennett - Commercial Photographer
							</h3>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col xs={12} lg={6} className="order-1 order-md-0">
							<p>
								Hi, I’m Sarah and I have been passionate about photography ever
								since I was a young girl. I haven’t always been a photographer,
								but when I look back over my life, I realise that I have gone
								full circle, from using cameras and visiting my uncle’s dark
								room and watching with awe as he developed our photographs, to
								working in radio advertising and visualising the commercials in
								my head, to becoming a commercial photographer and imagining
								images in my mind before they are taken.
							</p>
						</Col>
						<Col xs={12} lg={6} className="order-0 order-md-1">
							<StaticImage src="../images/meet-sarah.jpg" alt="Sarah" />
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Refresh your company's image brand-new, bespoke photos that reflect where your business is now - and where you want it to go!"
				btnText="Let's Talk"
				url="/contact-us"
			/>

			<Footer />
		</>
	);
};

export default IndexPage;
