import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {

    const data = useStaticQuery(
        graphql`
          query {            
            heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
                publicURL                
              }           
            schemaLogo: file(relativePath: { eq: "logo.png" }) {
              publicURL
            }
          }
        `
      ); 



    const localBusinessSchema = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "@id": "https://photographer.sarah-bennett.com",
        "name": "Sarah Bennett Commercial Photographer",        
        "legalName": "Sarah Bennett t/a Sarah Bennett Commercial Photography",
        "description": "Sarah Bennett is a highly experienced commercial photographer based in the South East offering a wide range of photography services and corporate photography training to businesses in Eastbourne, Sussex and London. I work with B2B and B2C businesses to produce high quality commercial images that represent your brand, improve engagement and deliver results. I am highly experienced in all aspects of commercial photography, including product photography and food photography.",
        "url": "https://www.sarah-bennett.com",        
        "priceRange": "$$$",
        "areaServed": [{
          "@type": "Place",
          "name": "East Sussex",
          "sameAs": "https://en.wikipedia.org/wiki/East_Sussex"
        },
        {
          "@type": "Place",
          "name": "West Sussex",
          "sameAs": "https://en.wikipedia.org/wiki/West_Sussex"
        },
        {
            "@type": "Place",
            "name": "London",
            "sameAs": "https://en.wikipedia.org/wiki/London"
        }], 
      
        "email": "sarah@sarah-bennett.com",
        "currenciesAccepted": "GBP",
        "numberOfEmployees": "1",
        "location": {
          "@type": "City",
        "name": "Eastbourne",
        "sameAs": "https://en.wikipedia.org/wiki/Eastbourne"
      },        
        "foundingLocation": {
          "@type": "City",
            "name": "Eastbourne",
            "sameAs": "https://en.wikipedia.org/wiki/Eastbourne"
          
        },
        "slogan": "Professional Commercial Photographer",
        "telephone": "+441323384672",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Eastbourne",
            "addressRegion": "East Sussex",
            "postalCode": "BN21 2PN",
            "streetAddress": "105 Hurst Road",
            "addressCountry": "United Kingdom"  
        },            
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "0.26493980000273115",
          "longitude": "50.780358887843356"
        },
        "hasMap": "https://g.page/sarah-bennett-photography",
        "openingHours": "Mo,Tu,We,Th,Fr, 09:00-16:00",
        "openingHoursSpecification": [{
            "@type": "OpeningHoursSpecification",
            "closes": "16:00",
            "dayOfWeek": "http://schema.org/Monday",
            "opens": "09:00"
        }, {
            "@type": "OpeningHoursSpecification",
            "closes": "16:00",
            "dayOfWeek": "http://schema.org/Tuesday",
            "opens": "09:00"
        }, {
            "@type": "OpeningHoursSpecification",
            "closes": "16:00",
            "dayOfWeek": "http://schema.org/Wednesday",
            "opens": "09:00"
        }, {
            "@type": "OpeningHoursSpecification",
            "closes": "16:00",
            "dayOfWeek": "http://schema.org/Thursday",
            "opens": "09:00"
        }, {
            "@type": "OpeningHoursSpecification",
            "closes": "16:00",
            "dayOfWeek": "http://schema.org/Friday",
            "opens": "09:00"
        }],
            "founder": {
                "@type": "Person",
            "name": "Sarah Bennet",  
            "id": "https://www.sarah-bennet.com/who-we-are#sarah-bennet",
            "url": "https://www.sarah-bennett.com/about-us",
            "description": "Hi, I’m Sarah and I have been passionate about photography ever since I was a young girl. I haven’t always been a photographer, but when I look back over my life I realise that I have gone full circle, from using cameras and visiting my uncle’s dark room and watching with awe as he developed our photographs, to working in radio advertising and visualising the commercials in my head, to becoming a commercial photographer and imagining images in my mind before they are taken."
          },
        "aggregateRating": {
          "@type": "AggregateRating",
          "bestRating" : "5",
          "worstRating": "1",
          "ratingValue": "4.9",
          "reviewCount": "23",
          "url": "https://www.sarah-bennett.com/reviews"
        }, 
        "logo": `${data.schemaLogo.publicURL}`,
        "image": `${data.heroImg.publicURL}`,           
        "sameAs": [
            "https://www.sarah-bennett.com/",
            "https://www.facebook.com/sarahbennettcommercial/",
            "https://twitter.com/sarahbcomphoto",
            "https://www.instagram.com/sarahbennettcommercialphoto/",
            "https://www.linkedin.com/in/sarahbennettcommercial/",
            "https://www.youtube.com/channel/UCjC3FRZA57srjsyQT36XAqA",
            "https://g.page/sarah-bennett-photography",
            "https://www.brightonchamber.co.uk/member/sarah-bennett-commercial-photography",
            "https://www.businessmagnet.co.uk/company/sarahbennettcommercialphotography-279049.htm",
            "https://www.bark.com/en/gb/company/sarah-bennett-commercial-photography/kjzny/",
            "https://www.artsprofessional.co.uk/services/supplier/sarah-bennett-commercial-photography",
            "https://www.freeindex.co.uk/profile(sarah-bennett-commercial-photography)_686596.htm",
            "https://www.yelp.com/biz/sarah-bennett-commercial-photography-eastbourne-2",
            "https://tradesinsussex.co.uk/listing/sarah-bennett-commercial-photography/",
            "https://www.photographerlistings.org/Sarah-Bennett-Commercial-Photography/D8310.htm"
        ]
      }
    
    
    return (
        <Helmet>
        <script type="application/ld+json">{JSON.stringify(localBusinessSchema)}</script>
        </Helmet>

    )

}

export default LocalBusinessSchema